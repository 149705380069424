import * as React from "react";
import { Company, Layout } from "../components";
import Seo from "../components/Seo";

const CompanyPage = () => {
  return (
    <Layout>
      <Seo
        title="会社概要"
        description="東京横丁の会社情報をご紹介します。"
        keywords="東京横丁,とうきょうよこちょう,TOKYO YOKOCHO"
      />
      <Company />
    </Layout>
  );
};

export default CompanyPage;
